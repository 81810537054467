<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>


  <button

  (click)="reload()"
    mat-icon-button
    class="topbar-button-right img-button"
  >
    <i class="material-icons">refresh</i>
  </button>
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->

  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <i class="material-icons">person_pin</i>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/perfil']">
      <mat-icon>account_box_fill</mat-icon>
      <span>Perfil</span>
    </button>
    <button mat-menu-item (click)="abrirCustomizador()">
      <mat-icon> format_color_fill</mat-icon>
      <span>Aparência</span>
    </button>
  
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app_fill</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>
