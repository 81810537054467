import { JwtHelperService } from '@auth0/angular-jwt';

import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";

import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { Utilizador } from "../../models/utilizador";
import { of, BehaviorSubject, throwError } from "rxjs";
import { Endpoints, environment } from "environments/environment";
import { permissoes } from "app/shared/guards/user-role.guard";

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  jwtHelper = new JwtHelperService();
  isAuthenticated: Boolean;
  user: Utilizador = {};
  user$ = new BehaviorSubject<Utilizador>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "";
  APP_USER = "";

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/")
    );
  }

  get pegarUtilizador(): number {
    return this.user$.value.idUtilizador;
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http
      .post(environment.GetEndpoint(Endpoints.Autenticacao), {
        username,
        password,
      })
      .pipe(
        map((res: any) => {
          this.setUserAndToken(res.objecto.token, res.objecto.utilizador, true);
          this.signingIn = false;
          return res.objecto;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public checkTokenIsValid() {
    return of(this.user).pipe(
      map((profile: Utilizador) => {
        this.setUserAndToken(this.getJwtToken(), profile, true);
        this.signingIn = false;
        this.user$.value.permissoes.forEach((perm) => {
          permissoes.push(perm.codPermissao);
        });
        return profile;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {

    if (this.getJwtToken() != null) {
      const token:string = this.getJwtToken();
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  getJwtToken() {
    return this.ls.getItem('token');
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: Utilizador, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem('token',token);
    this.ls.setItem(this.APP_USER, user);
  }
}
