<div class="header-topnav mat-elevation-z2">
  <div class="container">
    <div class="topnav">
      <!-- App Logo -->
      <div class="topbar-branding">
        <h3  class="pt-8" >Kiami <b>ADMIN</b> </h3>
      </div>

      <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index;">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
              <mat-icon>{{item.icon}}</mat-icon> 
              {{item.name | translate}}
            </a>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{i}}" class="toggle"><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</label>
              <a matRipple><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</a>
              <input type="checkbox" id="drop-{{i}}" />
              <ul>
                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                  <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" 
                  *ngIf="itemLvL2.type !== 'dropDown'">
                    <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  
                    {{itemLvL2.name | translate}}
                  </a>
                  
                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                    <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  {{itemLvL2.name | translate}}</a>
                    <input type="checkbox" id="drop-{{i}}{{j}}" />
                    <!-- Level 3 -->
                    <ul>
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                        <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                          <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                          {{itemLvL3.name | translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <span fxFlex></span>
      <!-- End Navigation -->
      <!-- Theme Switcher -->

    
      <!-- Top left user menu -->
      <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <i class="material-icons">person_pin</i>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/perfil']">
      <mat-icon>account_box_fill</mat-icon>
      <span>Perfil</span>
    </button>
    <button mat-menu-item (click)="abrirCustomizador()">
      <mat-icon> format_color_fill</mat-icon>
      <span>Aparência</span>
    </button>
  
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app_fill</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
      <!-- Mobile screen menu toggle -->
      <button 
      mat-icon-button 
      class="mr-16" 
      (click)="toggleSidenav()" 
      *ngIf="layoutConf.isMobile">
        <mat-icon>menu</mat-icon>
      </button>

    </div>
  </div>
</div>