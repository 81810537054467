import { LocalStoreService } from "./shared/services/local-store.service";
import { JwtAuthService } from "app/shared/services/autenticacao/jwt-auth.service";
import { Utilizador } from "./shared/models/utilizador";
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { RoutePartsService } from "./shared/services/route-parts.service";
import { filter } from "rxjs/operators";
import { UILibIconService } from "./shared/services/ui-lib-icon.service";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  appTitle = "Kiami";
  pageTitle = "";
  ls: LocalStoreService;
  private isCustomizerOpen = new BehaviorSubject<boolean>(false);
  currentUser = new BehaviorSubject<Utilizador>({});
  get getCurrentUser(): Utilizador {
    return this.currentUser.value;
  }

  set setCurrentUser(value: Utilizador) {
    this.currentUser.next(value);
  }

  get getIsCustomizerValue(): boolean {
    return this.isCustomizerOpen.value;
  }
  set setIsCustomizerValue(value: boolean) {
    this.isCustomizerOpen.next(value);
  }

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
  ) {
    iconService.init();
  }
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {}

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
