<div class="handle" *ngIf="!isCustomizerOpen">
   
  </div>
<div id="app-customizer" *ngIf="isCustomizerOpen">
  <mat-card class="p-0">
    <mat-card-title class="m-0 light-gray">
      <div class="card-title-text" fxLayout="row wrap" fxLayoutAlign="center center">
        <button mat-flat-button [color]="viewMode === 'options' ? 'primary':''" (click)="viewMode = 'options'">Options</button>
        <span fxFlex="15px"></span>
        <button mat-flat-button [color]="viewMode === 'json' ? 'primary':''" (click)="viewMode = 'json'">Json</button>
        <span fxFlex></span>
        <button 
        class="card-control" 
        mat-icon-button
        (click)="closeCustomizer()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content *ngIf="viewMode === 'json'" style="min-height: 100vh">
        <!-- <pre class="text-muted">{{this.layoutConf | json}}</pre> -->
        <pre><code [egretHighlight]="this.layoutConf | json"></code></pre>
        <div>
          <span fxFlex></span>
          <a href="http://demos.ui-lib.com/egret-doc/#layout" target="_blank" mat-mini-fab><mat-icon>help</mat-icon></a>
        </div>
    </mat-card-content>

    <mat-card-content [perfectScrollbar] *ngIf="viewMode === 'options'">
      <p><small>Configure o painel como desejar</small></p>
      <div class="pb-16 mb-16 border-bottom">
        <h6 class="title text-muted">Layouts</h6>
        <mat-radio-group fxLayout="column" [(ngModel)]="selectedLayout" (change)="changeLayoutStyle($event)">
            <mat-radio-button [value]="'top'"> Navegação no topo </mat-radio-button>
            <mat-radio-button [value]="'side'"> Navegação no lado </mat-radio-button>
        </mat-radio-group>
      </div>

     
      <div class="pb-16 mb-16 border-bottom">
        <h6 class="title text-muted">Mudar Tema</h6>
        <div class="colors">
            <div class="color" *ngFor="let theme of egretThemes"
            (click)="changeTheme(theme)" [style.background]="theme.baseColor">
              <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
            </div>
        </div>
      </div>

      <div class="pb-16 mb-16 border-bottom">
        <h6 class="title text-muted">Cores do navegador</h6>
        <div class="colors">
            <div 
            class="color {{c.class}}" 
            *ngFor="let c of customizer.sidebarColors"
            (click)="customizer.changeSidebarColor(c)">
            <mat-icon class="active-icon" *ngIf="c.active">check</mat-icon>
          </div>
        </div>
      </div>

      <div class="pb-16 mb-16 border-bottom">
        <h6 class="title text-muted">Cores do Rodapé</h6>
        <div class="mb-16">
          <mat-checkbox [(ngModel)]="isFooterFixed" (change)="layout.publishLayoutChange({ footerFixed: $event.checked })" [value]="selectedLayout !== 'top'">Rodapé Fixo</mat-checkbox>
        </div>

        <div class="colors">
            <div 
            class="color {{c.class}}" 
            *ngFor="let c of customizer.footerColors"
            (click)="customizer.changeFooterColor(c)">
            <mat-icon class="active-icon" *ngIf="c.active">check</mat-icon>
          </div>
        </div>  
    </div>

 
    </mat-card-content>
  </mat-card>
</div>