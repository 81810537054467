import { Utilizador } from "../app/shared/models/utilizador";
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from "config";

export enum Endpoints {
  Autenticacao = "autenticacao/login",
  Instalacoes = "instalacoes",
  Servidores = "servidores",
  Scripts = "Scripts",
  Utilizadores = "utilizadores",
  AlterarSenha = "autenticacao/alterarSenha",
  Permissoes = "Permissoes",
  Perfis = "Permissoes/perfis",
  EstadoInstalacoes = "estadosInstalacoes",
  ScriptsInstalacoes = "instalacoes/scripts",
  OcorrenciasScript = "Scripts/instalacoes",
  ExecutarScript = "instalacoes/executarScript",
  GerarSenha = "utilizadores/gerarSenha",
  AtribuirPermissao = "utilizadores/permissoes",
  Licenciamentos = "tiposLicenciamento",
}

export enum Permissoes {
  InstalacoesCriar = "INSTACRIAR",
  InstalacoesApagar = "INSTAPAGAR",
  InstalacoesConsultar = "INSTCONSUL",
  InstalacoesEditar = "INSTEDITAR",
  InstalacoesEnviarNotificacoes = "INSTNOTENV",
  InstalacoesEditarModulos = "INSTEDIMOD",
  InstalacoesEditarUtilizadores = "INSTEDIUTI",
  InstalacoesEditarValidade = "INSTEDIVAL",
  InstalacoesExecutarScripts = "INSTEXESCR",
  UtilizadoresCriar = "UTILCRIUTI",
  UtilizadoresEditarPassword = "UTILEDIPAS",
  UtilizadoresApagar = "UTILAPAGAR",
  ScriptsCarregar = "SCRICARREG",
  ScriptsConsultar = "SCRICONSUL",
  PerfisCriar = "PERFICRIAR",
  PerfisEditar = "PERFIEDITA",
}

export const tableMessages = {
  emptyMessage: "Sem Registos",
  totalMessage: "Total",
  selectedMessage: "Selecionados",
};

export const environment = {
  production: false,
  apiURL: config.apiUrl,

  GetEndpoint(endpoint: string): string {
    return config.apiUrl + endpoint;
  },
};
