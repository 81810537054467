
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';





export const rootRouterConfig: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',

  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Sessão' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [

      {
        path: "dashboard",
        loadChildren: () => import('./views/app-dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },


      {
        path: 'dialogs',
        loadChildren: () => import('./views/app-dialogs/app-dialogs.module').then(m => m.AppDialogsModule),
        data: { title: 'Dialogs', breadcrumb: 'DIALOGS' }
      },
      {
        path: 'perfil',
        loadChildren: () => import('./views/app-perfil/profile.module').then(m => m.ProfileModule),
        data: { title: 'Seu Perfil', breadcrumb: 'Perfil' }
      },
      {
        path: 'scripts',
        loadChildren: () => import('./views/app-scripts/scripts.module').then(m => m.ScriptsModule),
        data: { title: 'Scripts', breadcrumb: 'SCRIPTS' }
      },
      {
        path: 'instalacoes',
        loadChildren: () => import('./views/app-instalacoes/instalacoes.module').then(m => m.InstalacoesModule),
        data: { title: 'Instalações', breadcrumb: 'INSTALACOES' }
      },
      {
        path: 'utilizadores',
        loadChildren: () => import('./views/app-utilizadores/utilizadores.module').then(m => m.UtilizadoresModule),
        data: { title: 'utilizadores', breadcrumb: 'UTILIZADORES' }
      },
      {
        path: 'configuracoes',
        loadChildren: () => import('./views/app-configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),
        data: { title: 'configuracoes', breadcrumb: 'CONFIGURACOES ' }
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

